.headers-timeline_main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headers-timeline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1200px;
}

.row-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 20px;
}

.headers {
  flex: 1;
  /*   padding: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.boxed {
  background-color: #343a40;
  width: 100%;
  min-height: 110px;
  /*   padding: 20px; */
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 #a3a3a3;
  color: rgb(255, 255, 255);
  font-weight: 700;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 999;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #2563eb;
  position: absolute;
  bottom: -15px;
}

.active-timelineOne {
  background-color: #2563eb;
  color: white;
  box-shadow: 0 0 10px 0 #7dd3fc;
}

.boxed:hover {
  background-color: #2563eb;
  color: white;
  box-shadow: 0 0 10px 0 #7dd3fc;
  cursor: pointer;
}

.timeline-card {
  min-height: 200px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border: 2px solid #2563eb;
  border-radius: 5px;
}

.timeline-card-header {
  display: flex;
  flex-direction: row;
  min-height: 40%;
  align-items: center;
  color: white;
  font-weight: 700;
}

.col-1 {
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
  background-color: #2563eb;
  text-align: center;
}

.head-2{
  margin-left: 30px;
  margin-bottom: 0;
  padding: 0;
  font-weight: bold;
}

/* .col-2 {
  width: 50%;
  text-align: center;
  background-color: #343a40;
  border-top-right-radius: 5px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
} */

.timeline-card-body {
  color: black;
  font-weight: 500;
  padding: 15px;
}

.timeline-card-mobile {
  display: none;
}

@media (max-width: 991px) {
  .row-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .show-mob-time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .hid-mob-time {
    display: none;
  }
  .timeline-card-mobile {
    display: block;
  }
  .timeline-card-xl {
    display: none;
  }
  .row-one {
    flex-wrap: wrap;
  }
  .timeline-card {
    width: 85%;
  }
  .headers {
    /* flex: 0 0 50%; */
    width: 100%;
    padding: 5px;
  }
  .boxed {
    width: 90%;
  }
  .col-1 {
    width: 100%;
    padding: 15px;
  }
  .col-2 {
    width: 100%;
    padding: 15px;
  }
  .timeline-card-header {
    flex-direction: column;
  }
}