/* Base styles for headers */
.contact-header,
.about-header,
.courses-header {
  height: 50vh; /* Consistent height across all headers */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.contact-header {
  background-image: url("./assets/contactus.png");
}

.about-header {
  background-image: url("./assets/aboutus.png");
}

.courses-header {
  background-image: url("./assets/courses.png"); /* Correct if needed */
}
/* Responsive adjustments for tablets */
@media (max-width: 768px) {
  .contact-header,
  .about-header,
  .courses-header {
    height: 60vh; /* Slightly larger height for better visibility on tablets */
    padding: 20px; /* Add some padding to avoid content touching the edges */
  }
}

/* Responsive adjustments for mobile phones */
@media (max-width: 480px) {
  .contact-header,
  .about-header {
    height: 40vh; /* Reduced height for mobile to save space */
    padding: 10px; /* Slightly less padding for smaller screens */
    flex-direction: column; /* Stack content vertically for better scrolling */
    background-position: top center; /* Adjust background to better suit portrait orientation */
  }
  .courses-header {
    height: auto;
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .hero-text {
    color: black; /* text color changes to black on mobile devices */
    font-weight: bolder;
  }
  .hero-background {
    background-position: center; /* Centralize background on smaller devices if needed */
  }
}
